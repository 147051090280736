<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo mt-2 mt-md-0">
        <b-img
          src="@/assets/images/simiicons/Logo.svg"
          alt="logo"
        />
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <span
            class="heading1"
          >
            Adventure starts here🚀
          </span>
          <div class="subheading mt-3 mt-md-0">
            Make your app management easy and fun!
          </div>
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group
                label="Username"
                label-for="register-username"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required|min:8"
                  vid="confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="register-confirm-password"
                label="Confirm Password"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-confirm-password"
                      v-model="confirmPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-confirm-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="form-group block">
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="!active"
                  @click.prevent="createUser"
                >
                  <div
                    v-if="spinner"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  Sign up
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
              pill
              @click="facebooklogin"
            >
              <img src="@/assets/images/simiicons/Facebook.svg">
            </b-button>
            <b-button
              variant="apple"
              href="javascript:void(0)"
              pill
            >
              <img src="@/assets/images/simiicons/Apple.svg">
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
              pill
              @click="googlelogin"
            >
              <img src="@/assets/images/simiicons/Google.svg">
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      userEmail: '',
      userImage: '',
      userImgSrc: '',
      password: '',
      confirmPassword: '',
      active: true,
      spinner: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    ...mapState(['users', 'currentuser']),

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    createUser() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          const resp = await this.$auth.createUserEmailAndPassword(
            this.userEmail,
            this.password,
          )
          if (resp.success) {
            const res = resp.data
            res.user.displayName = this.username
            try {
              await this.createPostUser(this.username)
              await this.$router.push('/')
            } catch (e) {
              this.$swal('Something Went Wrong')
            }
          } else {
            this.$swal(resp.errorMessage)
            await this.$router.push('/login')
          }
          this.spinner = false
        }
      })
    },
    async googlelogin() {
      const resp = await this.$auth.googlesignin()
      if (resp.success) {
        const res = resp.data
        res.user.displayName = this.username
        const { dataRes, error } = await this.googleFacebookPost(res)
        if (dataRes) {
          if (dataRes.user.is_phone_number_verified) {
            await this.$router.push('/')
          } else {
            await this.$router.push('/phone-verification')
          }
        } else {
          this.$swal(error.message || 'Something Went Wrong')
        }
      } else {
        this.$swal(resp.message)
      }
    },
    async facebooklogin() {
      const resp = await this.$auth.facebooksignin()
      if (resp.success) {
        const res = resp.data
        res.user.displayName = this.username
        const { dataRes, error } = await this.googleFacebookPost(res)
        if (dataRes) {
          if (dataRes.user.is_phone_number_verified) {
            await this.$router.push('/')
          } else {
            await this.$router.push('/phone-verification')
          }
        } else {
          this.$swal(error.message || 'Something Went Wrong')
        }
      } else {
        this.$swal(resp.message)
      }
    },
    async createPostUser(name) {
      return this.$axios
        .post('user', { name })
        .then(() => {
        }).catch(error => (error.message))
    },
    async googleFacebookPost(res) {
      const data = { name: res.user.reloadUserInfo.displayName, email: res.user.reloadUserInfo.email }
      return this.$axios
        .post('user', data)
        .then(() => {
        }).catch(error => ({ error }))
    },
    inputFileClicked(event) {
      document.getElementById('fileUpload').click(event)
    },
    uploadImage(event) {
      const obj = event.target.files[0]
      this.userImage = obj
      this.userImgSrc = URL.createObjectURL(obj)
    },
  },
}
/* eslint-disable global-require */
</script>

<style scoped lang="scss">
.subheading{
  font-size: 14px;
line-height: 30px;
color: #3d3d3d;
font-weight: 400;
font-family: "Montserrat";
}
.heading1{
width: 277px;
height: 30px;
font-size: 20px;
line-height: 22px;
color: #3d3d3d;
font-weight: 500;
font-family: "Montserrat";
}
.upload{
margin-left: -50px;
margin-bottom: -40px;
}
.userimg{
  width: 140px;
}
</style>
